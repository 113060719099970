import React, { useState } from 'react';
import styled from 'styled-components';
import { Section } from '../styled-components';
import { Container } from 'react-bootstrap';
import {
  Steps,
  Button,
  message,
  Row,
  Col,
  Form,
  Input,
  Radio,
  Checkbox,
  Select,
  InputNumber
} from 'antd';

const { Item } = Form;

const { Step } = Steps;
const steps = [
  {
    title: 'First',
    content: 'First-content',
  },
  {
    title: 'Second',
    content: 'Second-content',
  },
  {
    title: 'Last',
    content: 'Last-content',
  },
  {
    title: 'First',
    content: 'First-content',
  },
  {
    title: 'Second',
    content: 'Second-content',
  }, 
];

const Title = styled.h1`
  font-size: 2rem;
  margin: 2rem 0;
   @media(min-width: 768px){
    width: 70%;
    margin-bottom: 4rem;
   }
`
const StepContent = styled.div`
  padding: 4rem 0;
`

const StepTitle = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
`
const SuccesCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4rem;
`
const SuccessTitle = styled.p`
  font-size: 2rem;
`
const SuccesSubtitle = styled.p`
  
`

export default ()=> {
  const [current, setCurrent] = useState(0);
  const [success, setSuccess] = useState(false);
  const [form] = Form.useForm(null);

  const next = async() => {
    console.log(form.getFieldsValue(true))
    await form.validateFields();
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onFinish = async(values) => {
    try{
      await form.validateFields();
      console.log(values);
      setSuccess(true);
      form.resetFields();
      /*setTimeout(()=> {
        setSuccess(false);
        form.resetFields();
        setCurrent(0);
      }, 5000);      */
    }
    catch(e){
      console.log(e);
    }
  }

  return(
    <Section first>
      <Container>
      {
        !success && (
          <>
        <Title>
          Déjanos tus datos y te enviaremos una valorización comercial de tu propiedad
        </Title>
      <Steps current={current}>
        {steps.map(item => (
          <Step key={item.title} />
        ))}
      </Steps>          
          </>
        )
      }
      <StepContent>
        {
          success
          ?(
            <SuccesCont>
              <SuccessTitle>
                ¡Muchas gracias!
              </SuccessTitle>
              <SuccesSubtitle>
                Hemos recibido tu mensaje, nos contactaremos contigo lo antes posible. <br /> Equipo Ambiado Propiedades
              </SuccesSubtitle>
            </SuccesCont>                  
            )
          :(
            <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            {
              current === 0 && (
                <Row gutter={[32, 32]}>
                  <Col xs={24}>
                    <StepTitle>
                      Información de la propiedad
                    </StepTitle>
                  </Col>
                  <Col xs={24} md={12}>
                    <Item
                      label={<span style={{ fontWeight: "bold" }}>Tipo de propiedad:</span>}
                      name="propertyType"
                      initialValue="Departamento"
                      rules={[
                        {
                          required: true,
                          message: "Debe indicar el tipo de propiedad"
                        }
                      ]}
                    >
                      <Radio.Group defaultValue="Departamento">
                        <Radio value="Departamento">
                          Departamento
                        </Radio>
                        <Radio value="Casa">
                          Casa
                        </Radio>
                        <Radio value="Oficina">
                          Oficina
                        </Radio>
                        <Radio value="Terreno">
                          Terreno
                        </Radio>
                      </Radio.Group>
                    </Item>                
                  </Col>
                  <Col xs={24} md={12}>
                    <Item
                      label={<span style={{ fontWeight: "bold" }}>Calle:</span>}
                      name="street"
                      rules={[
                        {
                          required: true,
                          message: "Debe indicar la calle"
                        }
                      ]}                    
                    >
                      <Input />
                    </Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Item
                      label={<span style={{ fontWeight: "bold" }}>Numero:</span>}
                      name="number"
                      rules={[
                        {
                          required: true,
                          message: "Debe indicar el numero"
                        }
                      ]}                                        
                    >
                      <InputNumber />
                    </Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Item
                      label={<span style={{ fontWeight: "bold" }}>Unidad:</span>}
                      name="unity"
                    >
                      <InputNumber />
                    </Item>
                  </Col>                   
                  <Col xs={24} md={6}>
                    <Item
                      label={<span style={{ fontWeight: "bold" }}>Comuna:</span>}
                      name="commune"
                      rules={[
                        {
                          required: true,
                          message: "Debe indicar la comuna"
                        }
                      ]}                                        
                    >
                      <Input />
                    </Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Item
                      label={<span style={{ fontWeight: "bold" }}>Región:</span>}
                      name="region"
                      rules={[
                        {
                          required: true,
                          message: "Debe indicar la región"
                        }
                      ]}                                        
                    >
                      <Input />
                    </Item>
                  </Col>                                                                      
                </Row>              
              )
            }
            {
              current === 1 && (
                <Row gutter={[32, 32]}>
                  <Col xs={24}>
                    <StepTitle>
                      Caracteristicas de la propiedad
                    </StepTitle>
                  </Col>                
                  <Col xs={24} md={6}>
                    <Item
                      label={<span style={{ fontWeight: "bold" }}>Año de construcción:</span>}
                      name="constructionAge"
                    >
                      <Input />
                    </Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Item
                      label={<span style={{ fontWeight: "bold" }}>M2 útil:</span>}
                      name="util_surface"
                      rules={[
                        {
                          required: true,
                          message: "Debe indicar la superficie útil"
                        }
                      ]}                      
                    >
                      <Input />
                    </Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Item
                      label={<span style={{ fontWeight: "bold" }}>M2 totales:</span>}
                      name="total_surface"
                      rules={[
                        {
                          required: true,
                          message: "Debe indicar la superficie total"
                        }
                      ]}                                      
                    >
                      <Input />
                    </Item>
                  </Col>  
                  <Col xs={24} md={12}>
                    <Item
                      label={<span style={{ fontWeight: "bold" }}>Dormitorios:</span>}
                      name="bedrooms"
                    >
                      <Input />
                    </Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Item
                      label={<span style={{ fontWeight: "bold" }}>Baños:</span>}
                      name="bathrooms"
                    >
                      <Input />
                    </Item>
                  </Col>   
                  <Col xs={24} md={12}>
                    <Item
                      label={<span style={{ fontWeight: "bold" }}>La propiedad dispone de:</span>}
                      name="others-characteristics"
                    >
                      <Checkbox.Group>
                        <Checkbox value="Amoblado">
                          Amoblado
                        </Checkbox>
                        <Checkbox value="Piscina">
                          Piscina
                        </Checkbox>
                        <Checkbox value="Ninguno">
                          Ninguno
                        </Checkbox>                                            
                      </Checkbox.Group>
                    </Item>
                  </Col>                                                                             
                </Row>              
              )
            }       
            {
              current === 2 && (
                <Row gutter={[32, 32]}>
                  <Col xs={24}>
                    <StepTitle>
                      Otros detalles
                    </StepTitle>
                  </Col>                
                  <Col xs={24} md={24}>
                    <Item
                      label={<span style={{ fontWeight: "bold" }}>¿Cuál es el estado de conservación de la propiedad?</span>}
                      name="conservation-state"
                    >
                      <Select defaultValue="Nueva">
                        <Select.Option value="Nueva">
                          Nueva
                        </Select.Option>
                        <Select.Option value="Muy bueno">
                          Muy bueno
                        </Select.Option>                      
                        <Select.Option value="Regular">
                          Regular
                        </Select.Option>                      
                        <Select.Option value="Necesita reforma">
                          Necesita reforma
                        </Select.Option>                      
                      </Select>
                    </Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Item
                      label={<span style={{ fontWeight: "bold" }}>¿Tiene ampliaciones?</span>}
                      name="ampliaciones"
                    >
                      <Select defaultValue="Si">
                        <Select.Option value="Si">
                          Si
                        </Select.Option>
                        <Select.Option value="No">
                          No
                        </Select.Option>                                      
                      </Select>
                    </Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Item
                      label={<span style={{ fontWeight: "bold" }}>¿Estan regularizadas?</span>}
                      name="regularizada"
                    >
                      <Select defaultValue="Si">
                        <Select.Option value="Si">
                          Si
                        </Select.Option>
                        <Select.Option value="No">
                          No
                        </Select.Option>                                      
                      </Select>
                    </Item>
                  </Col>  
                  <Col xs={24}>
                    <Item
                      label={<span style={{ fontWeight: "bold" }}>La propiedad está:</span>}
                      name="ocupation"
                    >
                      <Select defaultValue="Desocupada">
                        <Select.Option value="Desocupada">
                          Desocupada
                        </Select.Option>
                        <Select.Option value="Habitada por el propietario Arrendada">
                          Habitada por el propietario Arrendada
                        </Select.Option>                                      
                        <Select.Option value="Ocupada (diferente al propietario)">
                          Ocupada (diferente al propietario)
                        </Select.Option>                                                            
                      </Select>
                    </Item>
                  </Col>                                                                            
                </Row>              
              )
            } 
            {
              current === 3 && (
                <Row gutter={[32, 32]}>
                  <Col xs={24}>
                    <StepTitle>
                      Situación de la propiedad
                    </StepTitle>
                  </Col>                
                  <Col xs={24} md={12}>
                    <Item
                      label={<span style={{ fontWeight: "bold" }}>¿Eres el propietario de la propiedad?</span>}
                      name="isOwner"
                    >
                      <Select defaultValue="Si">
                        <Select.Option value="Si">
                          Si
                        </Select.Option>
                        <Select.Option value="No">
                          No
                        </Select.Option>                                      
                      </Select>
                    </Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Item
                      label={<span style={{ fontWeight: "bold" }}>¿Que quieres hacer con la propiedad?</span>}
                      name="operation"
                    >
                      <Select defaultValue="Si">
                        <Select.Option value="Vender">
                          Vender
                        </Select.Option>
                        <Select.Option value="Arrendar">
                          Arrendar
                        </Select.Option>                                      
                      </Select>
                    </Item>
                  </Col>  
                  <Col xs={24}>
                    <Item
                      label={<span style={{ fontWeight: "bold" }}>¿Tienes publicado un anuncio en algún portal inmobiliario?</span>}
                      name="isPublished"
                    >
                      <Input.TextArea autoSize={{ minRows: 6 }} placeholder="(Si tienes publicada la propiedad en algún portal, copia aquí el anuncio, o bien, si la propiedad la tiene un corredor de propiedades, nos puedes dejar su contacto.)" />
                    </Item>
                  </Col>                                                                            
                </Row>              
              )
            }  
            {
              current === 4 && (
                <Row gutter={[32, 32]}>
                  <Col xs={24}>
                    <StepTitle>
                      Tus datos para enviarte una oferta
                    </StepTitle>
                  </Col>                
                  <Col xs={24}>
                    <Item
                      label={<span style={{ fontWeight: "bold" }}>Nombre y apellido</span>}
                      name="name"
                    >
                      <Input />
                    </Item>
                  </Col>
                  <Col xs={24}>
                    <Item
                      label={<span style={{ fontWeight: "bold" }}>Correo electrónico</span>}
                      name="email"
                      rules={[
                        {
                          required: true,
                          message:"Debe indicar un correo"
                        },
                        {
                          type:"email",
                          message:"El email no es valido"
                        }
                      ]}
                    >
                      <Input />
                    </Item>
                  </Col>  
                  <Col xs={24}>
                    <Item
                      label={<span style={{ fontWeight: "bold" }}>Teléfono</span>}
                      name="phone"
                    >
                      <Input />
                    </Item>
                  </Col>  
                </Row>              
              )
            }                                  
          </Form>
          )
        }
      </StepContent>
      <div className="steps-action">
        {current > 0 && !success && (
          <Button onClick={() => prev()} htmlType="button">
            Previo
          </Button>
        )}        
        {current < steps.length - 1 && !success && (
          <Button style={{ margin: '0 8px' }} type="primary" onClick={() => next()} htmlType="button">
            Siguiente
          </Button>
        )}
        {current === steps.length - 1 && !success && (
          <Button type="primary" htmlType="submit" onClick={()=> onFinish(form.getFieldsValue(true))}>
            Listo
          </Button>
        )}
      </div>
      </Container>
    </Section>
  )

  /*return(
    <>
      <Steps current={current}>
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
      <div className="steps-action">
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={() => message.success('Processing complete!')}>
            Done
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </div>
    </>
  )*/
}